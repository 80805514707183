import React, { useState, useEffect, Suspense, lazy } from 'react';
import axios from 'axios';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSignInAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';

// Lazy load semua komponen utama
const TherapistList = lazy(() => import('./components/TherapistList'));
const BookingForm = lazy(() => import('./components/BookingForm'));
const Login = lazy(() => import('./components/Login'));
const TherapistDashboard = lazy(() => import('./components/TherapistDashboard'));
const ChangePassword = lazy(() => import('./components/ChangePassword'));
const AdminDashboard = lazy(() => import('./components/AdminDashboard'));
const RegisterForm = lazy(() => import('./components/RegisterForm'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ForgotPasswordUser = lazy(() => import('./components/ForgotPasswordUser'));
const AdminLogin = lazy(() => import('./components/AdminLogin'));
const PublicTherapistPage = lazy(() => import('./components/PublicTherapistPage'));
const UserDashboard = lazy(() => import('./components/UserDashboard'));

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('login');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [contactCounts, setContactCounts] = useState({ prospect: 0, customer: 0, vvip: 0 });
  const [searchTerm, setSearchTerm] = useState('');

  // Fungsi untuk ambil contact counts
  const fetchContactCounts = async (therapistId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/prospects/count/${therapistId}`, {
        headers: { 'x-auth-token': token },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching contact counts:', error);
      return { prospect: 0, customer: 0, vvip: 0 };
    }
  };

  useEffect(() => {
    const getCounts = async () => {
      const therapistId = localStorage.getItem('therapistId');
      if (therapistId) {
        const counts = await fetchContactCounts(therapistId);
        setContactCounts(counts);
      }
    };
    getCounts();
  }, []);

  const handleRegisterSuccess = () => {
    setActiveTab('therapistList');
    navigate('/therapist-list');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setIsSidebarOpen(false);
    switch (tab) {
      case 'therapistList':
        navigate('/therapist-list');
        break;
      case 'login':
        navigate('/login');
        break;
      default:
        navigate('/login');
    }
  };

  const handleOpenModal = (therapist) => {
    setSelectedTherapist(therapist);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTherapist(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Komponen dynamic route /therapist/:therapistId
  const TherapistBooking = () => {
    const { therapistId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [localIsModalOpen, setLocalIsModalOpen] = useState(false);
    const [localSelectedTherapist, setLocalSelectedTherapist] = useState(null);

    useEffect(() => {
      const fetchTherapistById = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${config.API_URL}/therapists/${therapistId}`);
          setLocalSelectedTherapist(response.data);
          setLocalIsModalOpen(true);
        } catch (error) {
          console.error('Error fetching therapist details:', error);
          setError('Gagal memuat data terapis');
        } finally {
          setIsLoading(false);
        }
      };
      fetchTherapistById();
    }, [therapistId]);

    const handleLocalClose = () => {
      setLocalIsModalOpen(false);
      setLocalSelectedTherapist(null);
      navigate('/therapist-list');
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
      localIsModalOpen && localSelectedTherapist && (
        <div className="modal-overlay" onClick={handleLocalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-button"
              onClick={handleLocalClose}
              style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              ✕
            </button>
            <BookingForm
              selectedTherapist={localSelectedTherapist}
              onClose={handleLocalClose}
            />
          </div>
        </div>
      )
    );
  };

  const isPublicSlugRoute = /^\/(?!therapist-list|login|register|admin-dashboard|admin-login|therapist-dashboard|change-password|reset-password|forgot-password|therapist)\w[\w-]*$/.test(
    location.pathname
  );

  const shouldShowNavigation =
    !isPublicSlugRoute &&
    location.pathname !== '/therapist-dashboard' &&
    location.pathname !== '/admin-dashboard' &&
    location.pathname !== '/admin-login';

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="App">
      <header className="main-header">
        <div className="header-content">
          {location.pathname === '/therapist-list' && (
            <div className="search-filter-header">
              <input
                type="text"
                placeholder="Cari therapist mengikut nama, lokasi, perkhidmatan..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="header-search-input"
              />
            </div>
          )}
        </div>

        {shouldShowNavigation && (
          <button className="menu-toggle" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
          </button>
        )}
      </header>

      {/* Desktop nav */}
      {shouldShowNavigation && (
        <nav className="desktop-nav">
          <button
            className={`nav-button ${activeTab === 'therapistList' ? 'active' : ''}`}
            onClick={() => handleTabChange('therapistList')}
          >
            <FontAwesomeIcon icon={faList} /> Senarai Therapist
          </button>
          <button
            className={`nav-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabChange('login')}
          >
            <FontAwesomeIcon icon={faSignInAlt} /> Therapist Login
          </button>
        </nav>
      )}

      {/* Sidebar (mobile) */}
<div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
  <nav>
    <button className="close-sidebar" onClick={toggleSidebar}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
    <button
      className={`nav-button ${activeTab === 'therapistList' ? 'active' : ''}`}
      onClick={() => handleTabChange('therapistList')}
    >
      <FontAwesomeIcon icon={faList} /> Senarai Therapist
    </button>
    <button
      className={`nav-button ${activeTab === 'login' ? 'active' : ''}`}
      onClick={() => handleTabChange('login')}
    >
      <FontAwesomeIcon icon={faSignInAlt} /> Therapist Login
    </button>
  </nav>
</div>
{isSidebarOpen && (
  <div className="sidebar-overlay" onClick={toggleSidebar}></div>
)}

      {/* Content utama */}
      <main className="main-content">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/therapist-list"
              element={<TherapistList onBooking={handleOpenModal} searchTerm={searchTerm} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterForm onRegisterSuccess={handleRegisterSuccess} />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/therapist-dashboard" element={<TherapistDashboard />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password-user" element={<ForgotPasswordUser />} />
            <Route path="/therapist/:therapistId" element={<TherapistBooking />} />
            <Route path="/:therapistSlug" element={<PublicTherapistPage />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
          </Routes>
        </Suspense>
      </main>

      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <BookingForm selectedTherapist={selectedTherapist} onClose={handleCloseModal} />
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
